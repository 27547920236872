<template>
  <div class="container" :style="{backgroundImage: `url(${img.light.factory.home.bg})`}">
    <div class="map-tooltip" :style="{visibility: tooltip.show ? 'visible':'hidden', left: `${tooltip.x}px`, top: `${tooltip.y}px`}">
      <div class="tooltop-border" style="left: 0;top: 0;border-right: 0;border-bottom:0"></div>
      <div class="tooltop-border" style="right: 0;top: 0;border-left: 0;border-bottom:0"></div>
      <div class="tooltop-border" style="left: 0;bottom: 0;border-right: 0;border-top:0"></div>
      <div class="tooltop-border" style="right: 0;bottom: 0;border-left: 0;border-top:0"></div>
      <div class="tooltip-content">
        <div class="tooltip-content-header">
          <div class="tooltip-name">{{tooltip.name}}能耗统计</div>
          <div class="tooltip-area">建筑面积：{{tooltip.area}} m²</div>
        </div>
        <div class="tooltip-content-center">
          <div class="tooltip-energy">
            <div class="tooltip-energy-name">本月用电量：</div>
            <div class="tooltip-energy-value">{{tooltip.area}} KW/h</div>
          </div>
          <div class="tooltip-energy">
            <div class="tooltip-energy-name">日均能耗：</div>
            <div class="tooltip-energy-avg">{{tooltip.area}} KW</div>
          </div>
        </div>
        <div class="tooltip-content-footer">
          <div class="tooltip-energy">
            <div class="tooltip-energy-name">本年用电量：</div>
            <div class="tooltip-energy-value">{{tooltip.area}} KW/h</div>
          </div>
          <div class="tooltip-energy">
            <div class="tooltip-energy-name">月均能耗：</div>
            <div class="tooltip-energy-avg">{{tooltip.area}} KW</div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-map">
      <div v-for="n in 5" :key="n" @mouseover="pointerOver($event, n)" @mouseout="pointerOut" class="center-pointer" :style="{backgroundImage: `url(${img.light.factory.home.center[`pointer${n}`]})`, left: `${centers[n-1].x}%`, top: `${centers[n-1].y}%`}">
        {{centers[n-1].name}}
      </div>
    </div>
    <div class="home-left">
      <div class="device-total">
        <div class="device-total-title">
          <div class="icon"></div>设备统计
        </div>
        <div class="device-total-item">
          <img :src="img.light.factory.home.count.power" />
          <div class="item-right">
            <div class="item-right-title">配电柜</div>
            <div class="item-right-count">
              <div class="right-count-item" v-for="n in powerCount.length" :key="n">{{powerCount[n-1]}}</div>
            </div>
          </div>
        </div>
        <div class="device-total-item">
          <img :src="img.light.factory.home.count.monitor" />
          <div class="item-right">
            <div class="item-right-title">集中器</div>
            <div class="item-right-count">
              <div class="right-count-item" v-for="n in powerCount.length" :key="n">{{powerCount[n-1]}}</div>
            </div>
          </div>
        </div>
        <div class="device-total-item">
          <img :src="img.light.factory.home.count.light" />
          <div class="item-right">
            <div class="item-right-title">单灯</div>
            <div class="item-right-count">
              <div class="right-count-item" v-for="n in lightCount.length" :key="n">{{lightCount[n-1]}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="device-status" :style="{backgroundImage: `url(${img.light.factory.home.status.bg})`}">
        <div class="device-total-title">
          <div class="icon"></div>设备状况
        </div>
        <div class="device-total-item">
          <i-circle :percent="powerOnlineRate" :size="100" :stroke-width="10" :stroke-color="['#3576F9', '#3576F9', '#3576F9']" :trail-width="10" trail-color="#032E53" class="status-count-chart">
            <span class="chart-inner-text number">0%</span>
            <span class="chart-inner-text title">配电箱</span>
          </i-circle>
          <div class="device-status-count">
            <div class="status-count-item">
              <div class="count-item-title">
                <div class="item-title-name">在线数量</div>
                <div class="item-title-number">{{powerOnlineCount}}</div>
              </div>
              <div class="count-item-line">
                <div class="item-line-flag" :style="{left: `${powerOnlineRate}%`, background: `#3576F9`}"></div>
                <div class="item-line-stroke" :style="{width: `${powerOnlineRate}%`,background: `linear-gradient(90deg, #022037, #3576F9)`}"></div>
                <div class="item-line-trail"></div>
              </div>
            </div>
            <div class="status-count-item">
              <div class="count-item-title">
                <div class="item-title-name">离线数量</div>
                <div class="item-title-number">{{powerOfflineCount}}</div>
              </div>
              <div class="count-item-line">
                <div class="item-line-flag" :style="{left: `${100-powerOnlineRate}%`, background: `#3576F9`}"></div>
                <div class="item-line-stroke" :style="{width: `${100-powerOnlineRate}%`,background: `linear-gradient(90deg, #022037, #3576F9)`}"></div>
                <div class="item-line-trail"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="device-total-item">
          <i-circle :percent="powerOnlineRate" :size="100" :stroke-width="10" :stroke-color="['#55F1CC', '#55F1CC', '#55F1CC']" :trail-width="10" trail-color="#032E53" class="status-count-chart">
            <span class="chart-inner-text number">0%</span>
            <span class="chart-inner-text title">集中器</span>
          </i-circle>
          <div class="device-status-count">
            <div class="status-count-item">
              <div class="count-item-title">
                <div class="item-title-name">在线数量</div>
                <div class="item-title-number">{{powerOnlineCount}}</div>
              </div>
              <div class="count-item-line">
                <div class="item-line-flag" :style="{left: `${powerOnlineRate}%`, background: `#55F1CC`}"></div>
                <div class="item-line-stroke" :style="{width: `${powerOnlineRate}%`,background: `linear-gradient(90deg, #011627, #55F1CC)`}"></div>
                <div class="item-line-trail"></div>
              </div>
            </div>
            <div class="status-count-item">
              <div class="count-item-title">
                <div class="item-title-name">离线数量</div>
                <div class="item-title-number">{{powerOfflineCount}}</div>
              </div>
              <div class="count-item-line">
                <div class="item-line-flag" :style="{left: `${100-powerOnlineRate}%`, background: `#55F1CC`}"></div>
                <div class="item-line-stroke" :style="{width: `${100-powerOnlineRate}%`,background: `linear-gradient(90deg, #011627, #55F1CC)`}"></div>
                <div class="item-line-trail"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="device-total-item">
          <i-circle :percent="lightOnlineRate" :size="100" :stroke-width="10" :stroke-color="['#FBDE49', '#FBDE49', '#FBDE49']" :trail-width="10" trail-color="#032E53" class="status-count-chart">
            <span class="chart-inner-text number">{{lightOnlineRate}}%</span>
            <span class="chart-inner-text title">单灯</span>
          </i-circle>
          <div class="device-status-count">
            <div class="status-count-item">
              <div class="count-item-title">
                <div class="item-title-name">在线数量</div>
                <div class="item-title-number">{{lightOnlineCount}}</div>
              </div>
              <div class="count-item-line">
                <div class="item-line-flag" :style="{left: `${lightOnlineRate}%`, background: `#FBDE49`}"></div>
                <div class="item-line-stroke" :style="{width: `${lightOnlineRate}%`,background: `linear-gradient(90deg, #011525, #FBDE49)`}"></div>
                <div class="item-line-trail"></div>
              </div>
            </div>
            <div class="status-count-item">
              <div class="count-item-title">
                <div class="item-title-name">离线数量</div>
                <div class="item-title-number">{{lightOfflineCount}}</div>
              </div>
              <div class="count-item-line">
                <div class="item-line-flag" :style="{left: `${100-lightOnlineRate}%`, background: `#FBDE49`}"></div>
                <div class="item-line-stroke" :style="{width: `${100-lightOnlineRate}%`,background: `linear-gradient(90deg, #011525, #FBDE49)`}"></div>
                <div class="item-line-trail"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="home-center">
      <div class="center-space"></div>
      <div class="center-energy" :style="{backgroundImage: `url(${img.light.factory.home.energy.bg})`}">
        <!-- <div class="device-total-title">
          <div class="icon"></div>能耗分析
        </div> -->
        <div ref="energyChart" class="energy-chart"></div>
        <div class="chart-icon"></div>
        <!-- <LineChart ref="lineChart1" :config="config" id="lineChart1" style="height: 240px; width: 100%;" /> -->
      </div>
    </div>
    <div class="home-right">
      <div class="alarm-total" :style="{backgroundImage: `url(${img.light.factory.home.alarm.bg})`}">
        <div class="device-total-title">
          <div class="icon"></div>报警统计
        </div>
        <div class="alarm-total-area">
          <div class="alarm-total-item alarm-fetal-item">
            <div class="alarm-count">0</div>
            <div class="alarm-name">严重报警</div>
          </div>
          <div class="alarm-total-item alarm-common-item">
            <div class="alarm-count">0</div>
            <div class="alarm-name">普通报警</div>
          </div>
          <div class="alarm-total-item alarm-pre-item">
            <div class="alarm-count">0</div>
            <div class="alarm-name">预报警</div>
          </div>
        </div>
        <div class="alarm-item-area">
          <div class="alarm-item-title">配电柜</div>
          <div class="alarm-item-content">
            <div class="alarm-content-item">
              <img :src="img.light.factory.home.alarm.power.current" />
              <div class="alarm-count">0</div>
              <div class="alarm-name">电流异常</div>
            </div>
            <div class="alarm-content-item">
              <img :src="img.light.factory.home.alarm.power.voltage" />
              <div class="alarm-count">0</div>
              <div class="alarm-name">电压异常</div>
            </div>
            <div class="alarm-content-item">
              <img :src="img.light.factory.home.alarm.power.door" />
              <div class="alarm-count">0</div>
              <div class="alarm-name">柜门异常</div>
            </div>
          </div>
        </div>
        <div class="alarm-item-area">
          <div class="alarm-item-title">单灯</div>
          <div class="alarm-item-content">
            <div class="alarm-content-item">
              <img :src="img.light.factory.home.alarm.light.current" />
              <div class="alarm-count">0</div>
              <div class="alarm-name">电流异常</div>
            </div>
            <div class="alarm-content-item">
              <img :src="img.light.factory.home.alarm.light.voltage" />
              <div class="alarm-count">0</div>
              <div class="alarm-name">电压异常</div>
            </div>
            <div class="alarm-content-item">
              <img :src="img.light.factory.home.alarm.light.offline" />
              <div class="alarm-count">0</div>
              <div class="alarm-name">离线异常</div>
            </div>
          </div>
        </div>
      </div>
      <div class="alarm-real" :style="{backgroundImage: `url(${img.light.factory.home.alarm.real.bg})`}">
        <div class="device-total-title">
          <div class="icon"></div>实时报警
        </div>
        <div class="alarm-real-title">
          <div class="real-col-name real-col-header">名称</div>
          <div class="real-col-status real-col-header">状态</div>
          <div class="real-col-time real-col-header">时间</div>
        </div>
        <div class="alarm-real-list">
          <div v-for="(item, idx) in selectedAlarms" :key="idx" :class="['alarm-real-item', idx % 2 == 1 ? 'alarm-real-span' : '']">
            <div class="real-col-name">{{getProductDescription(item.productId)}}</div>
            <div class="real-col-status">{{powerBoxAlarmTypes[item.content]}}</div>
            <div class="real-col-time">{{new Date(item.startTime).format('yyyy-MM-dd HH:mm:ss')}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from 'echarts/core';
import {
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  DataZoomComponent,
  TitleComponent
} from 'echarts/components';
import {
  LineChart
} from 'echarts/charts';
import {
  CanvasRenderer
} from 'echarts/renderers';

echarts.use(
  [ToolboxComponent, TooltipComponent, GridComponent, LegendComponent, LineChart, CanvasRenderer, DataZoomComponent, TitleComponent]
);
import { mapState } from 'vuex'
export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
      centers: [
        { x: 46, y: 39, name: '一号区域', area: 0 },
        { x: 27, y: 24, name: '二号区域', area: 0 },
        { x: 13, y: 13, name: '三号区域', area: 0 },
        { x: 80, y: 23, name: '四号区域', area: 0 },
        { x: 55, y: 7, name: '五号区域', area: 0 },
      ],
      tooltip: {
        show: false,
        name: '',
        area: 0,
        x: 100,
        y: 100,
      },
      config: {
        title: ``
      },
      chart: null,
      option: null,
    }
  },
  watch: {
  },
  computed: {
    ...mapState('common', ['weeks','powerBoxAlarmTypes']),
    ...mapState("group", ["selectedDevices", "selectedNode"]),
    selectedAlarms: function () {
      return [];
    },
    powerOnlineCount: function () {
      return 0;
    },
    powerOfflineCount: function () {
      return 0;
    },
    powerOnlineRate: function () {
      let total = this.powerOnlineCount + this.powerOfflineCount;
      if (total == 0) return 0;
      return parseInt((this.powerOnlineCount * 100 / total).toFixed(0));
    },
    lightOnlineCount: function () {
      return 0;
    },
    lightOfflineCount: function () {
      return 0;
    },
    lightOnlineRate: function () {
      let total = this.lightOnlineCount + this.lightOfflineCount;
      if (total == 0) return 0;
      return parseInt((this.lightOnlineCount * 100 / total).toFixed(0));
    },
    powerCount: function () {
      return (0 + '').padStart(5, 0);
    },
    lightCount: function () {
      return (0 + '').padStart(5, 0);
    },
  },
  mounted: function () {
    this.$Notice.config({
      top: 220,
      duration: 10
    });
    this.initChart();
  },
  destroyed: function () {
  },
  methods: {
    getProductDescription: function (productId) {
      let prods = this.selectedDevices.filter(item => item.id == productId);
      if (prods.length == 0) return '';
      return `${prods[0].name}`;
    },
    pointerOver: function (e, n) {
      this.tooltip.x = e.target.offsetLeft + e.target.parentNode.offsetLeft - 200;
      this.tooltip.y = e.target.offsetTop + e.target.parentNode.offsetTop;
      let item = this.centers[n - 1];
      this.tooltip.name = item.name;
      this.tooltip.area = item.area;
      this.tooltip.show = true;
    },
    pointerOut: function () {
      this.tooltip.show = false;
    },
    initOption: function () {
      this.option = {
        title: {
          text: '能耗分析',
          left: 15,
          textStyle: {
            color: '#FFFFFF'
          },
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        backgroundColor: 'transparent',
        grid: {
          // top: 50,
          bottom: 30,
          left: 60,
          right: 40,
          borderColor: '#0A3364',
          backgroundColor: 'transparent',
          show: true
        },
        // toolbox: {
        //   feature: {
        //     // dataView: {show: true, readOnly: false},
        //     // magicType: {show: true, type: ['line', 'bar']},
        //     // restore: {show: true},
        //     saveAsImage: {show: true}
        //   }
        // },
        // dataZoom: [
        //   {
        //     show: true,
        //     realtime: true,
        //     start: 70,
        //     end: 100,
        //     xAxisIndex: [0]
        //   },
        //   {
        //     type: 'inside',
        //     realtime: true,
        //     start: 70,
        //     end: 100,
        //     xAxisIndex: [0]
        //   }
        // ],
        legend: {
          // data: [],
          type: 'scroll',
          right: 50,
          textStyle: {
            color: '#FFFFFF',
          },
          data: []
        },
        xAxis: [
          {
            type: 'time',
            name: '日期',
            nameTextStyle: {
              fontSize: 12,
              fontFamily: 'Microsoft YaHei',
              fontWeight: 400,
              color: '#FFFFFF',
              opacity: 0.6,
            },
            splitNumber: 12,
            axisLabel: {
              show: true,
              color: '#FFFFFF',
              // formatter: '{dd}'
            },
            splitLine: {
              show: true,
              interval: 0,
              lineStyle: {
                color: '#0A3364'
              },
            },
            axisLine: {
              show: false,
            },
            // boundaryGap: ['20%', '20%'],
            // data: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
            // axisPointer: {
            //     type: 'shadow'
            // }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '',
            nameTextStyle: {
              fontSize: 12,
              fontFamily: 'Microsoft YaHei',
              fontWeight: 400,
              color: '#FFFFFF',
              opacity: 0.6,
            },
            axisLabel: {
              color: '#FFFFFF',
            },
            splitLine: {
              show: false,
            },
            // boundaryGap: true,
            // boundaryGap: ['20%', '20%']
          },
        ],
        series: [
          // {
          //     name: '一号厂房',
          //     type: 'line',
          //     smooth: 0.6,
          //     symbol: 'none',
          //     lineStyle: {
          //       // color: '#5470C6',
          //       width: 1
          //     },
          //     data: [['2021-11-15',2.0], ['2021-11-16',4.9], ['2021-11-17',17.0], ['2021-11-18',23.2], ['2021-11-19',25.6], ['2021-11-20',76.7], ['2021-11-21',135.6], ['2021-11-22',162.2], ['2021-11-23',32.6], ['2021-11-24',20.0], ['2021-11-25',6.4], ['2021-11-26',3.3]]
          // },
          // {
          //     name: '二号厂房',
          //     type: 'line',
          //     smooth: 0.6,
          //     symbol: 'none',
          //     lineStyle: {
          //       // color: '#5470C6',
          //       width: 1
          //     },
          //     data: [['2021-11-15',3.0], ['2021-11-16',5.9], ['2021-11-17',27.0], ['2021-11-18',15.2], ['2021-11-19',25.6], ['2021-11-20',76.7], ['2021-11-21',135.6], ['2021-11-22',162.2], ['2021-11-23',32.6], ['2021-11-24',20.0], ['2021-11-25',6.4], ['2021-11-26',3.3]]
          // },
          // {
          //     name: '三号厂房',
          //     type: 'line',
          //     smooth: 0.6,
          //     symbol: 'none',
          //     lineStyle: {
          //       // color: '#5470C6',
          //       width: 1
          //     },
          //     data: [['2021-11-15',4.0], ['2021-11-16',6.9], ['2021-11-17',37.0], ['2021-11-18',25.2], ['2021-11-19',25.6], ['2021-11-20',76.7], ['2021-11-21',135.6], ['2021-11-22',162.2], ['2021-11-23',32.6], ['2021-11-24',20.0], ['2021-11-25',6.4], ['2021-11-26',3.3]]
          // },
          // {
          //     name: '四号厂房',
          //     type: 'line',
          //     smooth: 0.6,
          //     symbol: 'none',
          //     lineStyle: {
          //       // color: '#5470C6',
          //       width: 1
          //     },
          //     data: [['2021-11-15',5.0], ['2021-11-16',7.9], ['2021-11-17',4.0], ['2021-11-18',16.2], ['2021-11-19',25.6], ['2021-11-20',76.7], ['2021-11-21',135.6], ['2021-11-22',162.2], ['2021-11-23',32.6], ['2021-11-24',20.0], ['2021-11-25',6.4], ['2021-11-26',3.3]]
          // },
          // {
          //     name: '五号厂房',
          //     type: 'line',
          //     smooth: 0.6,
          //     symbol: 'none',
          //     lineStyle: {
          //       // color: '#5470C6',
          //       width: 1
          //     },
          //     data: [['2021-11-15',6.0], ['2021-11-16',8.9], ['2021-11-17',7.0], ['2021-11-18',33.2], ['2021-11-19',25.6], ['2021-11-20',76.7], ['2021-11-21',135.6], ['2021-11-22',162.2], ['2021-11-23',32.6], ['2021-11-24',20.0], ['2021-11-25',6.4], ['2021-11-26',3.3]]
          // },
        ]
      };
      for (let c of this.centers) {
        this.option.legend.data.push(c.name);
        let serie = {
          name: c.name,
          type: 'line',
          smooth: 0.6,
          symbol: 'none',
          lineStyle: {
            // color: '#5470C6',
            width: 1
          },
          data: []
        };
        let date = new Date();
        for (let i = 0; i < 20; i++) {
          date.setDate(date.getDate() - i);
          serie.data.push([date.format('yyyy-MM-dd'), 0]);
        }
        this.option.series.push(serie);
      }
    },
    initChart: function () {
      this.chart = echarts.init(this.$refs.energyChart);
      this.initOption();
      this.chart.setOption(this.option);
    },
  }
}
</script>
<style scoped>
.tooltip-content {
  /* border: solid 1px red; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  /* padding: 15px 10px; */
}
.tooltip-content-header {
  /* border: solid 1px red; */
  height: 60px;
  flex: none;
  margin: 10px 15px;
}
.tooltip-content-center {
  /* border: solid 1px red; */
  height: 50px;
  flex: auto;
  background: #023359;
  padding: 0 15px;
}
.tooltip-content-footer {
  /* border: solid 1px red; */
  height: 50px;
  flex: auto;
  padding: 0 15px;
}
.tooltip-name {
  /* border: solid 1px red; */
  height: 30px;
  line-height: 30px;
  flex: none;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.tooltip-area {
  /* border: solid 1px red; */
  height: 20px;
  line-height: 20px;
  flex: none;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.tooltip-energy {
  /* border: solid 1px red; */
  height: 30px;
  flex: auto;
  display: flex;
  align-items: center;
}
.tooltip-energy-name {
  /* border: solid 1px red; */
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  width: 100px;
  flex: none;
}
.tooltip-energy-value {
  /* border: solid 1px red; */
  font-size: 16px;
  font-family: Bahnschrift;
  font-weight: 400;
  color: #51e7f3;
  text-align: right;
  width: 80px;
  flex: auto;
}
.tooltip-energy-avg {
  /* border: solid 1px red; */
  font-size: 16px;
  font-family: Bahnschrift;
  font-weight: 400;
  color: #f2cf41;
  text-align: right;
  width: 80px;
  flex: auto;
}
.container {
  width: 100%;
  height:calc( 100% - 92px );
  background-color: #010b13;
  background-size: 100% auto;
  background-repeat: no-repeat;
  display: flex;
  /* border: solid 1px red; */
  overflow: auto;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding: 10px 0 40px 0;
  /* margin: 20px 0 40px 0; */
}
.container::-webkit-scrollbar {
  display: none;
}
.home-left {
  /* border: solid 1px red; */
  width: 500px;
  height: 855px;
  flex: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0 30px;
}
.device-total {
  /* border: solid 1px red; */
  height: 280px;
  flex: auto;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}
.device-total-title {
  /* border: solid 1px red; */
  height: 30px;
  flex: none;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
  z-index: 10;
}
.device-total-title .icon {
  margin: 0 10px;
  border-style: solid;
  border-width: 7px 0 7px 7px;
  border-color: transparent transparent transparent #6efffa;
  /* box-shadow: 0px 0px 6px 1px #51C3C5; */
  width: 0px;
  height: 0px;
}
.device-total-item {
  /* border: solid 1px red; */
  height: 10px;
  flex: auto;
  display: flex;
  align-items: center;
}
.device-total-item img {
  margin: 0 28px;
  width: 105px;
  flex: none;
}
.item-right {
  /* border: solid 1px red; */
  width: 10px;
  height: 98px;
  padding: 10px 0;
  flex: auto;
  display: flex;
  flex-direction: column;
}
.item-right-title {
  /* border: solid 1px red; */
  height: 30px;
  line-height: 30px;
  flex: none;
  font-size: 16px;
  font-family: lianmengqiyilushuaizhengruiheiti;
  font-weight: 400;
  color: #ffffff;
}
.item-right-count {
  /* border: solid 1px red; */
  height: 10px;
  flex: auto;
  display: flex;
  align-items: center;
}
.right-count-item {
  width: 30px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  background: #070e24;
  box-shadow: inset 0px 0px 4px 0px #5ab3ff;
  border-radius: 2px;
  font-size: 30px;
  font-family: LcdD;
  font-weight: 400;
  color: #00d8ff;
  margin-right: 3px;
}
.device-status {
  /* border: solid 1px red; */
  height: 470px;
  flex: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  padding: 30px 20px;
}
.status-count-chart {
  /* border: solid 1px red; */
  height: 100px;
  width: 100px;
  flex: none;
  margin: 0 20px;
}
.chart-inner-text {
  display: block;
}
.title {
  font-size: 14px;
  font-family: Bahnschrift;
  font-weight: 400;
  color: #ffffff;
}
.number {
  font-size: 30px;
  font-family: Bahnschrift;
  font-weight: 400;
  color: #ffffff;
}
.device-status-count {
  /* border: solid 1px red; */
  height: 100px;
  width: 100px;
  flex: auto;
  display: flex;
  flex-direction: column;
}
.status-count-item {
  /* border: solid 1px red; */
  height: 10px;
  flex: auto;
  display: flex;
  flex-direction: column;
}
.count-item-title {
  /* border: solid 1px red; */
  padding-top: 8px;
  height: 30px;
  display: flex;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.item-title-name {
  width: 10px;
  flex: auto;
}
.item-title-number {
  width: 10px;
  flex: auto;
  text-align: right;
}
.count-item-line {
  height: 10px;
  flex: auto;
  position: relative;
}
.item-line-trail {
  position: absolute;
  bottom: 10px;
  width: 100%;
  height: 3px;
  background: #032e53;
  border-radius: 2px;
}
.item-line-stroke {
  position: absolute;
  bottom: 10px;
  /* width: 80%; */
  height: 3px;
  /* background: linear-gradient(90deg, #022037, #3576F9); */
  border-radius: 2px;
  z-index: 1;
}
.item-line-flag {
  position: absolute;
  bottom: 7px;
  /* left: 80%; */
  width: 4px;
  height: 9px;
  /* background: #3576F9; */
  border-radius: 2px;
  z-index: 1;
}
.home-center {
  /* border: solid 1px red; */
  width: 890px;
  height: 855px;
  flex: auto;
  display: flex;
  flex-direction: column;
  /* padding: 30px 0; */
}
.center-space {
  /* border: solid 1px red; */
  height: 280px;
  flex: auto;
  /* position: relative; */
}
.map-tooltip {
  position: absolute;
  width: 210px;
  height: 210px;
  background: linear-gradient(180deg, #00335b, #024980);
  opacity: 0.8;
}
.tooltop-border {
  position: absolute;
  width: 10px;
  height: 10px;
  border: 1px solid #6efffa;
  opacity: 0.6;
}
.container-map {
  /* border: solid 1px red; */
  position: absolute;
  left: 20%;
  top: 150px;
  width: 55%;
  height: 700px;
}
.center-pointer {
  position: absolute;
  width: 105px;
  height: 80px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  padding-top: 2px;
  cursor: pointer;
}
.center-energy {
  /* border: solid 1px red; */
  padding: 20px;
  height: 280px;
  flex: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
}
.chart-icon {
  /* border: solid 1px red; */
  position: absolute;
  top: 26px;
  left: 25px;
  border-style: solid;
  border-width: 7px 0 7px 7px;
  border-color: transparent transparent transparent #6efffa;
  /* box-shadow: 0px 0px 6px 1px #51C3C5; */
  width: 0px;
  height: 0px;
  z-index: 1;
}
.energy-chart {
  /* border: solid 1px red; */
  position: absolute;
  width: 870px;
  height: 240px;
  z-index: 0;
}
.home-right {
  /* border: solid 1px red; */
  width: 500px;
  height: 855px;
  flex: none;
  /* display: flex;
  flex-direction: column; */
  padding: 0 30px;
}
.alarm-total {
  /* border: solid 1px red; */
  height: 480px;
  flex: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-bottom: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.alarm-total-area {
  height: 120px;
  flex: none;
  /* border: solid 1px red; */
  display: flex;
  padding: 10px 20px;
}
.alarm-total-item {
  /* border: solid 1px red; */
  width: 100px;
  flex: auto;
  margin: 0 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
}
.alarm-total-item .alarm-count {
  font-size: 30px;
  font-family: Bahnschrift;
  font-weight: 400;
  color: #ffffff;
}
.alarm-total-item .alarm-name {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  opacity: 0.8;
}
.alarm-fetal-item {
  background: linear-gradient(0deg, transparent, #a52d2e);
  border: 0;
  /* border-bottom: 0; */
  opacity: 0.9;
  /* border-radius: 6px; */
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.alarm-common-item {
  background: linear-gradient(0deg, transparent, #917f2b);
  border: 0 solid #ac8c33;
  opacity: 0.9;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.alarm-pre-item {
  background: linear-gradient(0deg, transparent, #17659c);
  border: 0 solid #1e77b4;
  opacity: 0.9;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.alarm-item-area {
  /* border: solid 1px red; */
  height: 100px;
  flex: auto;
  display: flex;
  flex-direction: column;
}
.alarm-item-title {
  /* border: solid 1px red; */
  padding-left: 20px;
  height: 30px;
  line-height: 25px;
  flex: none;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  margin: 0;
}
.alarm-item-content {
  /* border: solid 1px red; */
  height: 100px;
  flex: auto;
  display: flex;
  padding: 10px 30px;
}
.alarm-content-item {
  /* border: solid 1px red; */
  width: 100px;
  flex: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.alarm-content-item .alarm-count {
  /* height: 20px; */
  /* border: solid 1px red; */
  font-size: 24px;
  font-family: Bahnschrift;
  font-weight: 400;
  color: #f2cf41;
}
.alarm-content-item .alarm-name {
  /* border: solid 1px red; */
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.alarm-real {
  /* border: solid 1px red; */
  height: 365px;
  flex: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  /* margin-bottom: 40px; */
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
}
.alarm-real-title {
  /* border: solid 1px red; */
  /* height: 30px; */
  display: flex;
  align-items: center;
  /* margin-top: 10px; */
  height: 40px;
}
.real-col-header {
  /* border: solid 1px red; */
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #5ef1fd;
  text-align: center;
  padding-left: 0;
}
.alarm-real-list {
  /* border: solid 1px red; */
  height: 10px;
  flex: auto;
  overflow: auto;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.alarm-real-list::-webkit-scrollbar {
  display: none;
}
.alarm-real-item {
  display: flex;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.alarm-real-span {
  background: #002746;
}
.real-col-name {
  padding-left: 20px;
  /* border: solid 1px red; */
  width: 50px;
  flex: auto;
}
.real-col-status {
  /* border: solid 1px red; */
  width: 100px;
  flex: none;
}
.real-col-time {
  /* border: solid 1px red; */
  width: 140px;
  flex: none;
}
</style>
