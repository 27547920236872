<template>
  <div class="profiles-page" v-if="funCodes('dco')">
    <div v-for="(el,index) in profilesList" :key="index" class="profiles-flex">
      <div class="profiles-box" :class="[activeId==index?'cover':'']" :style="{backgroundImage: `url(${img.light.factory.monitor.adjust.modelBg})`}" @click.stop="profilesBut(el,index)">
        <div class="profiles-cover" v-if="activeId!=index"></div>
        <div class="profiles-name"><i :class="[activeId==index?'cover':'']"></i>{{el.name}}</div>
        <img class="profiles-icon" :src="img.light.factory.monitor.adjust.computer2" alt="">
        <div class="profiles-list">
          <div class="profiles-area">
            <div class="profiles-region">
              <img :src="img.light.factory.monitor.adjust.region" alt="">
              区域
            </div>
            <div class="profiles-brightness">
              <img :src="img.light.factory.monitor.adjust.brightness" alt="">
              亮度
            </div>
          </div>
          <template v-for="(subs,indexs) in el.content">
            <div class="profiles-area" :key="indexs" v-if="indexs<5">
              <div class="profiles-region" style="text-align:left; padding-left: 10px">
                {{subs.groupName}}
              </div>
              <div class="profiles-brightness">
                {{subs.bright}}
              </div>
            </div>
            <div class="profiles-area" :key="indexs" v-if="indexs==6">
              <div class="profiles-region">更多</div>
              <div class="profiles-brightness">...</div>
            </div>
          </template>
        </div>
        <img class="profiles-shadow" :src="img.light.factory.monitor.adjust.shadow" alt="">
      </div>
    </div>
    <div>
      <Modal v-model="modalShow" title="提示" @on-ok="ok" @on-cancel="cancel">
        <p>你确定修改为<span class="modalTisp">{{activeData.name}}</span>吗？</p>
        <div v-if="activeData.content && activeData.content.length>5" style=" margin-top: 20px;">
          <div class="profiles-area">
            <div class="profiles-region">
              <img :src="img.light.factory.monitor.adjust.region" alt="">
              区域
            </div>
            <div class="profiles-brightness">
              <img :src="img.light.factory.monitor.adjust.brightness" alt="">
              亮度
            </div>
          </div>
          <template v-for="(subs,indexs) in activeData.content">
            <div class="profiles-area profiles-area-list" :key="indexs">
              <div class="profiles-region">
                {{subs.groupName}}
              </div>
              <div class="profiles-brightness">
                {{subs.bright}}
              </div>
            </div>
          </template>
        </div>
      </Modal>
    </div>
    <ModelMonitorCmdArgs v-model="showArgModal" :item="cmdItem" />
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import ModelMonitorCmdArgs from "@/views/common/command/ModelMonitorCmdArgs";
export default {
  name: "patternPage",
  components: {
    ModelMonitorCmdArgs,
  },
  props: {
    dataLoaded: {
      type: Number,
      default: 0
    },
    adjust: {
      type: Object,
      default() { return {}; }
    },
    areas: {
      type: Array,
      default() { return []; }
    },
  },
  data() {
    return {
      activeId: -1,
      profilesList: [],
      activeData: {},
      modalShow: false,
      showArgModal: false,
      cmdItem: {},
      boxHeight: '430px',
    };
  },
  computed: {
    ...mapGetters('auth', ['funCodes']),
    ...mapState("common", ["weeks", "productCodes", "lightMonitorCode"]),
    ...mapState("group", ["selectedDevices", "selectedNode"]),
    ...mapState("cmd", ["cmdResultRefresh", "commands", 'windowOnResize']),
    currentStation: function () {
      if (this.selectedDevices.length > 0) return this.selectedDevices[0];
      return null;
    },
    selectedStations: function () {
      return this.selectedDevices;
    },
  },
  watch: {
    dataLoaded() {
      this.initProfiles();
    },
  },
  mounted: function () {
    this.initProfiles();
  },
  methods: {
    initProfiles: function () {
      if (!this.currentStation) {
        this.$set(this, 'profilesList', []);
        return;
      }
      let groupId = 0, stationId = this.currentStation.id;
      this.$axios.post(`//${this.domains.trans}/station/config/QueryProfile`, { groupId, stationId }).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          this.$set(this, 'profilesList', res.data);
        }
      });
    },
    areaCheck(id) {
      let area = this.areas.filter(p => p.id == id);
      if (area.length < 1) return [];
      return area[0].name;
    },
    areaGroup(id) {
      let area = this.areas.filter(p => p.id == id);
      if (area.length < 1) return [];
      return area[0].content.group;
    },
    deviceIdCheck(id) {
      if (!this.selectedStations) return [];
      let data = [];
      this.selectedStations.forEach(el => {
        data = data.concat([...el.params])
      });
      let device = data.filter(p => p.type == 504 && p.stationId == id);
      if (device.length < 1) return 0;
      return device[0].deviceId;
    },
    cancel() {
      this.activeId = -1;
    },
    ok() {
      if (!this.adjust) {
        this.$Message.warning('请先配置站点调光属性');
        return;
      }
      if (this.adjust.deviceId <= 0) {
        this.$Message.warning('请先配置站点调光属性的关联设备');
        return;
      }
      let code = 'handGroup1';
      let name = '组控单灯';
      let cmds = [];
      this.activeData.content.forEach(el => {
        cmds.push({
          code: code,
          name: `区域[${el.groupName}]${name}亮度[${el.bright}]结果`,
          args: {
            no: el.groupCode,
            expire: el.expire,
            bright1: el.bright,
            color1: el.color,
            bright2: 100 - el.bright,
            color2: 100 - el.color,
          },
          list: [this.adjust.id],
        });
      });
      this.cmdItem = {code: code, name: name, cmds: cmds, flag: 4};
      this.showArgModal = true;
      // this.activeData.content.forEach(el => {
      //   cmds.push({
      //     id: el.area,
      //     devName: `${this.activeData.name},${el.groupName}`,
      //     deviceId: this.adjust.deviceId,
      //     code: "handGroup1",
      //     name: "组控单灯",
      //     args: {
      //       no: el.groupCode,
      //       expire: el.expire,
      //       bright1: el.bright,
      //       color1: el.color,
      //       bright2: 100 - el.bright,
      //       color2: 100 - el.color,
      //     },
      //   })
      // });
      // this.cmdItem = {
      //   cmd: { code: "freeAdjust", name: "模式调光", flag: 4 },
      //   devs: cmds,
      // };
      // this.showArgModal = true;
    },
    profilesBut(el, index) {
      this.modalShow = true;
      this.activeId = index;
      this.activeData = el;
    },
  },
};
</script>
<style scoped>
.profiles-page {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.profiles-flex {
  float: left;
}
.profiles-box {
  width: 247px;
  height: 428px;
  margin-left: 60px;
  background-repeat: no-repeat;
  position: relative;
  cursor: pointer;
  margin-top: 30px;
  background-color: #002c58;
  box-sizing: border-box;
  border: 1px solid #09427e;
  border-radius: 10px;
  background-position: -1px -2px;
  overflow: hidden;
}
.profiles-box.cover {
  border: 2px solid #2a8afa;
}
.profiles-cover {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 10px;
  opacity: 0.4;
  background: #000;
}

.profiles-name i.cover {
  background: #00ff3c;
}
.profiles-name i {
  width: 10px;
  height: 10px;
  background: #8ec4e3;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.profiles-name {
  width: 100%;
  height: 42px;
  text-align: center;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 42px;
}
.profiles-icon {
  display: block;
  margin: 30px auto;
}

.profiles-area {
  display: flex;
  justify-content: space-evenly;
  width: 200px;
  margin: 0 auto;
}
.profiles-list {
  margin-bottom: 20px;
}
.profiles-region,
.profiles-brightness {
  width: 100px;
  height: 36px;
  background: #002447;
  border: 1px solid #0e55a2;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 36px;
}
.profiles-region img,
.profiles-brightness img {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}
.modalTisp {
  color: #57a3f3;
}
.profiles-shadow {
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 24px;
}
</style>
