<template>
  <div class="power-container">
    <div class="power-areas">
      <CommonTree />
    </div>
    <div class="power-content" :style="{backgroundImage: `url(${img.light.factory.monitor.adjust.cleverControlBg})`,}">
      <div class="power-title" v-if="currentStation">
        <div class="power-title-center">
          站点：{{currentStation.name}}
          <img :src="img.light.factory.monitor.adjust.monitorBg" />
        </div>
        <div class="bright-env">
          <div class="bright-env-item">
            <img :src="img.light.factory.light.bright.out" />
            <div class="env-item-title">室外光：</div>
            <div class="env-item-value">0</div>
          </div>
          <div class="bright-env-item">
            <img :src="img.light.factory.light.bright.in" />
            <div class="env-item-title">室内光：</div>
            <div class="env-item-value" :key="luxDataRefreshed">{{currentLux}}</div>
          </div>
          <div class="bright-env-item">
            <img :src="img.light.factory.light.bright.temp" />
            <div class="env-item-title">温度：</div>
            <div class="env-item-value">0</div>
          </div>
        </div>
      </div>
      <div class="switch-button-list">
        <!-- <div class="power-button-item" @click="selectMenu('powerbox')" :style="{backgroundImage: `url(${currentMenu == 'powerbox' ? img.light.factory.power.button.hover : img.light.factory.power.button.bg})`}">智能配电</div> -->
        <div class="power-button-item" v-if="funCodes(...['lmv', 'lma', 'lme','lmd'])" :class="[currentMenu == 'pattern'?'active':'']" @click="selectMenu('pattern')">
          <img :src="img.light.factory.monitor.adjust.model" />
          <span>模式</span>
        </div>
        <div class="power-button-item" v-if="funCodes(...['lav', 'laa', 'lae','lad'])"  :class="[currentMenu == 'monitor'?'active':'']" @click="selectMenu('monitor')">
          <img :src="img.light.factory.monitor.adjust.dimming" />
          <span>调光</span>
        </div>
        <div class="power-button-item" v-if="funCodes(...['lv', 'la', 'le','ld'])" :class="[currentMenu == 'lights'?'active':'']" @click="selectMenu('lights')">
          <img :src="img.light.factory.monitor.adjust.lamp" />
          <span>单灯</span>
        </div>
      </div>
      <div class="area-content">
        <ModelMonitorItem v-if="currentMenu == 'monitor' && currentAdjustType == 'st6036' && funCodes(...['lav', 'laa', 'lae','lad'])" :areas="areas" :adjust="currentAdjustParam" :lights="lights" :dataLoaded="dataLoaded" />
        <ModelMonitorItemC228 v-if="currentMenu == 'monitor' && currentAdjustType == 'c228' && funCodes(...['lav', 'laa', 'lae','lad'])" :areas="areas" :adjust="currentAdjustParam" :lights="lights" :dataLoaded="dataLoaded" />
        <MonitorItemCat1 v-if="currentMenu == 'monitor' && !currentAdjustType && funCodes(...['lav', 'laa', 'lae','lad'])" :areas="areas" :adjust="currentAdjustParam" :lights="lights" :dataLoaded="dataLoaded" />
        <ModelLightItem v-if="currentMenu == 'lights' && funCodes(...['lv', 'la', 'le','ld'])" :areas="areas" :adjust="currentAdjustParam" :lights="lights" :dataLoaded="dataLoaded" />
        <patternPage v-if="currentMenu == 'pattern' && funCodes(...['lmv', 'lma', 'lme','lmd'])" :areas="areas" :adjust="currentAdjustParam" :lights="lights" :dataLoaded="dataLoaded" />
        <!-- <ModelPowerItem v-if="currentMenu == 'powerbox'" :areas="areas" :adjust="currentAdjustParam" :lights="lights" :dataLoaded="dataLoaded" /> -->
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
// import CommonTree from '@/views/common/tree/Index'
import CommonTree from '../tree/Index'
import ModelLightItem from './LightItem'
// import ModelLightList from './LightList'
import patternPage from './pattern'
import ModelMonitorItem from './MonitorItem'
import ModelMonitorItemC228 from './MonitorItemC228'
import MonitorItemCat1 from './MonitorItemCat1'
export default {
  name: 'FactoryAdjustIndex',
  components: {
    CommonTree,
    ModelLightItem,
    ModelMonitorItem,
    ModelMonitorItemC228,
    MonitorItemCat1,
    // ModelPowerItem,
    patternPage,
  },
  data() {
    return {
      currentMenu: 'pattern',
      currentLux: 0,
      areas: [],
      adjusts: [],
      lights: [],
      currentAdjustParam: null,
      currentAdjustType: null,
      dataLoaded: 0,
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes']),
    ...mapState('common', ['productCodes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    ...mapState('cmd', ['cmdResultRefresh', 'cmds', 'luxDataRefreshed', 'luxes']),
    currentStation: function () {
      if (this.selectedNode.type == this.productCodes.station) {
        return this.selectedNode;
      } else if (this.selectedDevices.length > 0) {
        return this.selectedDevices[0];
      }
      return null;
    },
  },
  watch: {
    $route: {
      handler: function (route) {
        if (route.query.id) {
          setTimeout(() => {
            this.$store.commit('group/selectNodeById', { nodeId: route.query.id, type: this.productCodes.station })
          }, 200)
        }
      },
      immediate: true
    },
    luxDataRefreshed() {
      if (this.currentAdjustParam == null) {
        this.currentLux = 0;
        return;
      }
      let lux = this.luxes[this.currentAdjustParam.id];
      if (!lux) {
        this.currentLux = 0;
        return;
      }
      this.currentLux = lux.val;
      return;
    },
    selectedNode() {
      // console.log('station chianged 1', this.currentStation, this.currentAdjustParam)
      this.initAdjustAndAreas();
    }
  },
  mounted: function () {
    this.$store.dispatch('group/getGroupAndStations', 0)
    this.initAdjustAndAreas();
  },
  methods: {
    initAdjustAndAreas: function () {
      let groupId = 0, stationId = 0;
      if (this.selectedNode.type == this.productCodes.station) {
        stationId = this.selectedNode.id;
      } else if (this.selectedDevices.length > 0) {
        stationId = this.selectedDevices[0].id;
      }
      if (stationId <= 0) {
        this.$set(this, 'areas', []);
        this.$set(this, 'adjusts', []);
        this.$set(this, 'lights', []);
        this.currentAdjustParam = null;
        this.currentAdjustType = null;
        return;
      }
      let p1 = this.$axios.post(`//${this.domains.trans}/station/config/QueryArea`, { groupId, stationId });
      let p2 = this.$axios.post(`//${this.domains.trans}/station/config/QueryAdjust`, { groupId, stationId });
      let p3 = this.$axios.post(`//${this.domains.trans}/station/config/QueryAllLight`, { groupId, stationId });
      Promise.all([p1, p2, p3]).then(res => {
        if (res[0].code != 0) {
          // this.$Message.error(res[0].data);
        } else {
          this.$set(this, 'areas', res[0].data);
        }
        if (res[1].code != 0) {
          // this.$Message.error(res[1].data);
        } else {
          this.$set(this, 'adjusts', res[1].data);
          if (res[1].data.length == 0) {
            this.currentAdjustParam = null;
            this.currentAdjustType = null;
          } else {
            this.currentAdjustParam = res[1].data[0];
            this.currentAdjustType = res[1].data[0].deviceType;
          }
        }
        if (res[2].code != 0) {
          // this.$Message.error(res[2].data);
        } else {
          this.$set(this, 'lights', res[2].data);
        }
        this.dataLoaded++;
      })
    },
    selectMenu: function (menu) {
      this.currentMenu = menu;
    },
  }
}
</script>
<style scoped>
.switch-button-list {
  width: 50px;
  height: 320px;
  position: fixed;
  right: 0px;
  top: 50%;
  margin-top: -160px;
}
.power-button-item {
  cursor: pointer;
  width: 50px;
  height: 147px;

  background: #012e5b;
  border-radius: 12px 0px 0px 12px;
  text-align: center;
  margin-bottom: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.power-button-item.active {
  background: linear-gradient(270deg, #034da0, #2ea3d4);
}
.power-button-item img {
  width: 20px;
  height: 24px;
  margin-top: 30px;
}
.power-button-item span {
  width: 18px;
  margin: 3px 0 0 0px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 36px;
  color: #feffff;
}
.area-content {
  height: 100px;
  flex: auto;
  /* border: solid 1px red; */
  overflow: hidden;
  /* scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative; */
}
.area-content::-webkit-scrollbar {
  display: none;
}
.power-container {
  /* border: solid 1px red; */
  width: 100%;
  display: flex;
  background-color: #011220;
}
.power-areas {
  /* border: solid 1px red; */
  width: 300px;
  margin: 3px;
  flex: none;
  background-color: #001f37;
  display: flex;
  flex-direction: column;
}
.power-content {
  /* border: solid 1px red; */
  margin: 3px;
  width: 0;
  flex: auto;
  display: flex;
  flex-direction: column;
  /* background: linear-gradient(180deg, #001323, #0e244a); */
  box-shadow: inset 0px 0px 32px 0px #013565;

  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
  background-position: center 0;
  /* align-items: center; */
}
.power-title {
  padding: 0 10px 10px;
  height: 100px;
  flex: none;
  text-align: center;
  font-size: 28px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #51e7f3;
  position: relative;
}
.power-title-center {
  padding-top: 8px;
  /* border: solid 1px red; */
  width: 400px;
  height: 100%;
  margin: 0 auto;
  position: relative;
}
.power-title-center img {
  position: absolute;
  bottom: 20px;
  left: 0;
  /* left: ; */
}
.bright-env {
  /* border: solid 1px red; */
  position: absolute;
  top: 20px;
  right: 60px;
  /* width: 200px; */
  height: 30px;
  display: flex;
}
.bright-env-item {
  display: flex;
  align-items: center;
}
.bright-env-item img {
  margin: 0 5px;
}
.env-item-title {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.env-item-value {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #51e7f3;
}
</style>
