<template>
  <Modal v-model="showModal" :mask-closable="false" width="1000px">
    <div slot="header">编辑情景模式</div>
    <Form ref="form" :model="form" :rules="rules" :label-width="110">
      <FormItem prop="name" label="名称" style="margin-right: 15px">
        <div class="flex-row">
          <Input type="text" v-model="form.name" placeholder="名称"></Input>
        </div>
      </FormItem>
      <FormItem prop="connectType" label="" :label-width="0">
        <table class="table-style" border="1" cellspacing="0">
          <tr v-for="(item, idx) in form.content" :key="idx">
            <td style="width: 100px;text-align:right;">控制区域：</td>
            <td style="width: 200px">
              <Select v-model="item.groupId" v-if="areas">
                <template v-for="(a, didx) in areas">
                  <Option :key="didx" :value="a.id">{{a.name}}[分组号：{{a.code}}]</Option>
                </template>
              </Select>
            </td>
            <td style="width: 120px;text-align:right;">亮度设置(%)：</td>
            <td style="width: 100px">
              <Input type="number" v-model="item.bright" number>
              <!-- <span slot="prepend">区域名称</span> -->
              <!-- <span slot="append">KW</span> -->
              </Input>
            </td>
            <td style="width: 120px;text-align:right;">色温设置(%)：</td>
            <td style="width: 100px">
              <Input type="number" v-model="item.color" number>
              <!-- <span slot="prepend">区域名称</span> -->
              <!-- <span slot="append">KW</span> -->
              </Input>
            </td>
            <td style="width: 120px;text-align:right;">执行时长(min)：</td>
            <td style="width: 100px">
              <Input type="number" v-model="item.expire" number>
              <!-- <span slot="prepend">区域名称</span> -->
              <!-- <span slot="append">KW</span> -->
              </Input>
            </td>
            <td style="width: 50px;text-align:center;"><Button type="info" @click="addSub(idx)">+</Button></td>
            <td style="width: 50px;text-align:center;"><Button type="error" @click="delSub(idx)">-</Button></td>
          </tr>
        </table>
      </FormItem>
    </Form>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">取消</Button>
      <Button type="primary" :loading="loading" @click="ok">保存</Button>
    </div>
    <!-- <ModalSelectGroup v-model="showSelectModal" @newGroupSelected="newGroupSelected" /> -->
  </Modal>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'ModalEdit',
  components:{
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default(){ return {};}
    },
    areas: {
      type: Array,
      default(){ return [];}
    },
  },
  data () {
    return {
      showModal: this.value,
      // showSelectModal: false,
      loading: false,
      isAdd: false,
      types: [],
      groupName: '',
      mains: [],
      devices: [],
      form: {
        id: 0,
        name: '',
        content: [
          {groupId: 0, bright: 0, color: 0, expire: 100},
        ]
      },
      rules: {
      },
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if(newVal){
        // console.log('modal level edit mounted', this.item)
        this.isAdd = this.item.isAdd;
        if(!this.isAdd){
          this.form.id = this.item.data.id;
          this.form.name = this.item.data.name;
          this.form.content.splice(0, this.form.content.length);
          for(let item of this.item.data.content){
            this.form.content.push({groupId: item.groupId, bright: item.bright, color:item.color, expire: item.expire});
          }
          if(this.form.content.length == 0){
            this.form.content.push({groupId: 0, bright: 0, color: 0, expire: 100});
          }
        }else{
          this.form.id = 0;
          this.form.name = '';
          this.form.content = [{groupId: 0, bright: 0, color: 0, expire: 100}]
        }
      }
    },
    showModal(val){
      this.$emit('showChanged', val);
    },
  },
  computed: {
    ...mapState('common', ['weeks', 'deviceTypes', 'productCodes', 'powerBoxTypes']),
  },
  mounted: function(){
  },
  methods: {
    addSub: function (idx) {
      this.form.content.splice(idx+1, 0, { groupId: 0, bright: 0, color: 0, expire: 100 })
    },
    delSub: function (idx) {
      if (this.form.content.length <= 1) {
        this.$Message.warning('至少需要一个区域');
        return;
      }
      this.form.content.splice(idx, 1)
    },
    ok: async function () {
      if(this.form.name.trim().length == 0){
        this.$Message.warning('请输入名称');
        return;
      }
      if (this.form.content.length == 0) {
        this.$Message.warning('请至少设置一个控制区域');
        return;
      }
      for (let item of this.form.content) {
        if (item.groupId <= 0) {
          this.$Message.warning('请设置控制区域');
          return;
        }
        if (this.form.content.filter(p => p.groupId == item.groupId).length > 1) {
          this.$Message.warning('不能重复设置控制区域');
          return;
        }
        if (item.bright < 0 || item.bright > 100) {
          this.$Message.warning('亮度必须在 0 - 100 之间');
          return;
        }
        if (item.color < 0 || item.color > 100) {
          this.$Message.warning('色温必须在 0 - 100 之间');
          return;
        }
        if (item.expire <= 0) {
          this.$Message.warning('请设置执行时长');
          return;
        }
      }
      this.$axios.post(`//${this.domains.trans}/station/config/SaveProfile`, this.form).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$Message.info('保存成功');
          this.showModal = false;
          this.$emit('saved', {isAdd: this.isAdd, data: res.data});
        }
      })
    },
    cancel () {
      this.showModal = false;
      // this.$refs.form.resetFields();
    },
  }
}
</script>
<style scoped>
.table-style,
.table-style td {
  border: solid 1px rgba(7, 156, 156, 0.356);
}
.form-item-group{
  border: solid 1px rgba(129, 124, 124, 0.377);
  border-radius: 12px;
  margin-bottom: 15px;
}
.group-header{
  /* border: solid 1px blue; */
  background-color: rgba(221, 216, 216, 0.808);
  margin-bottom: 15px;
  height: 35px;
  border-radius: 12px 12px 0 0;
  line-height: 35px;
  font-weight: bold;
  padding: 0 10px;
}
  .demo-drawer-footer{
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: 1px solid #e8e8e8;
    padding: 10px 16px;
    text-align: right;
    background: #fff;
  }
  .form{
    height: calc(100% - 54px);
    overflow: auto;
  }
  .readonly{
    background: #eee
  }
</style>