<template>
<div class="alarm-rule-container">
  <div class="query-data-area">
    <Button v-if="funCodes('lpd')" type="error" @click="deleteParams" style="margin: 0 5px;">批量删除</Button>
  </div>
  <div class="alarm-data-area" ref="table1">
    <u-table ref="chkTable" :data="list" style="width: 100%;" use-virtual row-key="id" border stripe :height="tabHeight" default-expand-all>
      <u-table-column type="index" header-align="center"></u-table-column>
      <u-table-column type="selection" header-align="center"></u-table-column>
      <u-table-column prop="name" label="名称" width="150" header-align="center">
        <template slot-scope="params">
          {{params.row.name}}
        </template>
      </u-table-column>
      <u-table-column prop="sc" label="模式详情" sortable header-align="center">
        <template slot-scope="params">
          <table class="table-style" border="1" cellspacing="0">
            <tr v-for="(item, idx) in params.row.content" :key="`${params.id}_${idx}`">
              <td style="width: 100px;text-align:right;">控制区域：</td>
              <td style="width: 200px;text-align:left;">
                {{item.groupName}}
              </td>
              <td style="width: 120px;text-align:right;">亮度设置(%)：</td>
              <td style="width: 60px;text-align:center;">
                {{item.bright}}
              </td>
              <td style="width: 120px;text-align:right;">色温设置(%)：</td>
              <td style="width: 60px;text-align:center;">
                {{item.color}}
              </td>
              <td style="width: 120px;text-align:right;">执行时长(min)：</td>
              <td style="width: 80px;text-align:center;">
                {{item.expire}}
              </td>
            </tr>
          </table>
        </template>
      </u-table-column>
      <u-table-column width="120">
        <template slot="header">
          <AuthButton opCode='lpa' style="margin-right: 5px" @click="handleAdd">新增</AuthButton>
          <AuthButton opCode='lpv' style="margin-right: 5px" @click="getList">刷新</AuthButton>
        </template>
        <template slot-scope="scope">
          <AuthButton opCode='lpe' style="margin-right: 5px" @click="handleEdit(scope)">编辑</AuthButton>
          <AuthButton opCode='lpd' type="error" @click="handleDelete(scope)">删除</AuthButton>
        </template>
      </u-table-column>
    </u-table>
    <ModalEdit v-model="showEditModal" :item="editItem" :areas="areas" @saved="itemSaved" />
    <!-- <ModalArgEdit v-model="showArgModal" /> -->
  </div>
</div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import ModalEdit from './ModalEdit'
// import ModalArgEdit from './ModalArgEdit'
export default {
  name: 'ConfigProfileIndex',
  components:{
    ModalEdit,
    // ModalArgEdit,
  },
  props: {
  },
  data () {
    return {
      curTab: 'hour24',
      list: [],
      tabHeight: 600,
      editItem: {isAdd: true, data: {}},
      showEditModal: false,
      showArgModal: false,
      areas: [],
      btns: [
        {code: 'updatePowerRated', name: '设定额定功率'},
        {code: 'updateCurrentRated', name: '设定额定电流'},
        {code: 'updateCurrentRate', name: '设定电流倍率'},
        {code: 'updateLeakageRate', name: '设定漏电阈值'},
        {code: 'updateSteelRate', name: '设定防盗阈值'},
      ]
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes']),
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['weeks', 'productCodes', 'alarmTypes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
  },
  watch: {
    windowOnResize(){
      this.setTableHeight();
    },
  },
  mounted: function(){
    setTimeout(this.setTableHeight, 100);
    this.getAreas();
    this.getList();
  },
  destroyed: function(){
  },
  methods: {
    getAreaName: function(areaId){
      let ass = this.areas.filter(p => p.id == areaId);
      if(ass.length == 0)return '-';
      return `${ass[0].name}[分组号：${ass[0].content.group}]`
    },
    setTableHeight: function(){
      this.tabHeight = this.$refs.table1.clientHeight - 10;
    },
    handleAdd: function(){
      this.editItem = {isAdd: true, data: {}};
      this.showEditModal = true;
    },
    handleEdit: function(params){
      this.editItem = {isAdd: false, data: params.row};
      this.showEditModal = true;
    },
    itemSaved: function(){
      this.getList();
    },
    deleteParams: function(){
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if(chks.length == 0){
        this.$Message.warning('请选择要删除的记录');
        return;
      }
      let list = chks.map(p => p.id);
      this.$Modal.confirm({
        title: "提示",
        content: `确定要删除已选的[${list.length}]条记录吗?`,
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/config/DeleteProfile`, {list: list}).then(res => {
            if(res.code != 0){
              // this.$Message.error(res.data);
            }else{
              this.getList();
            }
          });
        }
      });
    },
    handleDelete: function(params){
      this.$Modal.confirm({
        title: "提示",
        content: `确定要删除[${params.row.name}]吗?`,
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/config/DeleteProfile`, {list: [params.row.id]}).then(res => {
            if(res.code != 0){
              // this.$Message.error(res.data);
            }else{
              this.getList();
            }
          });
        }
      });
    },
    getAreas: function(){
      this.$axios.post(`//${this.domains.trans}/station/config/QueryArea`, {}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$set(this, 'areas', res.data);
        }
      });
    },
    getList: function(){
      this.$axios.post(`//${this.domains.trans}/station/config/QueryProfile`, {}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$set(this, 'list', res.data);
        }
      });
    },
  }
}
</script>
<style scoped>
.table-style,
.table-style td {
  border: solid 1px rgba(7, 156, 156, 0.356);
}
.alarm-rule-container{
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  /* padding: 10px; */
  /* padding-right: 40px; */
  /* border: solid 1px red; */
}
.query-data-area{
  height: 45px;
  flex: none;
  display: flex;
  align-items: center;
  border: solid 1px rgba(143, 138, 138, 0.452);
  margin-bottom: 5px;
}
.alarm-data-area{
  /* border: solid 1px red; */
  height: 100px;
  flex: auto;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
  padding-left: 10px;
}
.station-alarm-tabs{
  height: 30px;
  flex: none;
  display: flex;
}
.station-alarm-tab{
  padding: 3px 8px;
  margin: 3px 3px 0 3px;
  border: solid 1px lightgray;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  background-color: white;
}
.tab-active{
  border: solid 1px rgb(135, 159, 224);
  background-color: rgb(135, 159, 224);
  color: white;
}
.station-alarm-tabcontent{
  height: 500px;
  flex: auto;
  /* border: solid 1px red; */
}
/* .box-body{
  height: 0;
  flex: auto;
  margin-top: 5px;
  border: solid 1px red;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}
.box-body::-webkit-scrollbar {
  display: none;
} */
</style>