import { render, staticRenderFns } from "./LightItem.vue?vue&type=template&id=625cc2d3&scoped=true"
import script from "./LightItem.vue?vue&type=script&lang=js"
export * from "./LightItem.vue?vue&type=script&lang=js"
import style0 from "./LightItem.vue?vue&type=style&index=0&id=625cc2d3&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "625cc2d3",
  null
  
)

export default component.exports