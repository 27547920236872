<template>
  <Modal v-model="showModal" :mask-closable="false" width="1000px">
    <div slot="header">组织 【{{item.name}}】 应用 【{{item.appName}}】 的角色信息</div>
    <div class="edit-area">
      <vxe-table ref="chkTable" border show-overflow show-header-overflow :data="list" :height="600" :row-config="{isHover: true}">
        <vxe-column type="seq" width="60" ></vxe-column>
        <vxe-column field="name" title="名称" width="200" header-align="center">
          <template #default="{ row }">
            {{row.name}}
          </template>
        </vxe-column>
        <vxe-column field="remark" title="说明" width="530" header-align="center">
          <template #default="{ row }">
            {{row.remark}}
          </template>
        </vxe-column>
        <vxe-column width="175" title="操作" fixed="right">
          <template #header>
            <Button v-if="funCodes('sra')" size="small" type="primary" style="margin-right: 5px" @click="handleAdd">新增</Button>
            <Button v-if="funCodes('srv')" size="small" type="primary" style="margin-right: 5px" @click="getList">刷新</Button>
          </template>
          <template #default="scope">
            <Button v-if="funCodes('sre')" size="small" type="primary" style="margin-right: 5px" @click="handleEdit(scope)">编辑</Button>
            <Button v-if="funCodes('srd')" size="small" type="error" style="margin-right: 5px" @click="handleDelete(scope)">删除</Button>
          </template>
        </vxe-column>
      </vxe-table>
    </div>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">关闭</Button>
    </div>
    <ModalRoleEdit v-model="showEditModal" :item="editItem" @saved="itemSaved" />
  </Modal>
</template>
<script>
import { mapGetters } from 'vuex'
import ModalRoleEdit from './ModalRoleEdit'
export default {
  name: 'ModalRoleList',
  components:{
    ModalRoleEdit,
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default(){ return {};}
    },
  },
  data () {
    return {
      showModal: this.value,
      editItem: {},
      showEditModal: false,
      loading: false,
      isAdd: false,
      list: [],
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes']),
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if(newVal){
        this.getList();
        console.log('check auth result', this.funCodes('sra'), this.funCodes('srv'), this.funCodes('sre'), this.funCodes('srd'))
      }
    },
    showModal(val){
      this.$emit('showChanged', val);
    },
  },
  mounted: function(){
  },
  methods: {
    handleAdd: function(params){
      this.editItem = {isAdd: true, data: params.row, cust: this.item};
      this.showEditModal = true;
    },
    handleEdit: function(params){
      this.editItem = {isAdd: false, data: params.row, cust: this.item};
      this.showEditModal = true;
    },
    handleDelete: function(params){
      this.$Modal.confirm({
        title: "提示",
        content: `确定要删除角色[${params.row.name}]吗?`,
        onOk: async () => {
          this.$axios.post(`site/customer/DeleteRoleOld`, {id: params.row.id}).then(res => {
            if(res.code != 0)return;
            this.getList();
          });
        }
      });
    },
    getList: function(){
      this.$axios.post(`site/customer/QueryOldRole`, {id: this.item.id}).then(res => {
        if(res.code != 0)return;
        this.$set(this, 'list', res.data);
        this.$refs.chkTable.reloadData(res.data)
      });
    },
    itemSaved: function(){
      this.getList();
    },
    cancel () {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.form-item-group{
  border: solid 1px rgba(129, 124, 124, 0.377);
  border-radius: 12px;
  margin-bottom: 15px;
}
.group-header{
  /* border: solid 1px blue; */
  background-color: rgba(221, 216, 216, 0.808);
  margin-bottom: 15px;
  height: 35px;
  border-radius: 12px 12px 0 0;
  line-height: 35px;
  font-weight: bold;
  padding: 0 10px;
}
.edit-area{
  /* border: solid 1px blue; */
  width: 100%;
  height: 600px;
  display: flex;
}
.form-area{
  width: 410px;
  flex: none;
}
.fun-area{
  width: 400px;
  flex: auto;
  margin-left: 20px;
  border: solid 1px rgba(216, 199, 199, 0.438);
  border-radius: 6px;
  overflow: auto;
  position: relative;
}
.chk-all{
  position: absolute;
  top: 10px;
  right: 10px;
}
.logo-upload {
  width: 410px;
  height: 114px;
  border: solid 1px gray;
  cursor: pointer;
}
.logo-upload input {
  visibility: hidden;
}
.logo-upload img {
  width: 410px;
  height: 114px;
}
.fun-list {
  display: flex;
  /* list-style: none; */
  flex-direction: column;
  margin: 8px 15px;
}
.chk-list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}
li {
  margin: 0 5px;
}
</style>