import { render, staticRenderFns } from "./PowerBoxCommand.vue?vue&type=template&id=375c2206&scoped=true"
import script from "./PowerBoxCommand.vue?vue&type=script&lang=js"
export * from "./PowerBoxCommand.vue?vue&type=script&lang=js"
import style0 from "./PowerBoxCommand.vue?vue&type=style&index=0&id=375c2206&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "375c2206",
  null
  
)

export default component.exports