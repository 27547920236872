<template>
  <div class="alarm-rule-container">
    <div class="query-data-area">
      用户名：
      <Input v-model="filter.code" placeholder="请输入用户名" clearable style="width: 200px; margin-right: 10px;"></Input>
      姓名：
      <Input v-model="filter.name" placeholder="请输入姓名" clearable style="width: 200px; margin-right: 10px;"></Input>
      手机号：
      <Input v-model="filter.mobile" placeholder="请输入手机号" clearable style="width: 200px; margin-right: 10px;"></Input>
      <Button type="primary" @click="search">查询</Button>
    </div>
    <div class="alarm-data-area" ref="table1">
      <vxe-table resizable border show-overflow row-key :row-config="{isHover: true}" :key="tabRefresh" :seq-config="{seqMethod}" :data="list" :scroll-y="{enabled: false}" height="auto">
        <vxe-column field="stats" title="状态" width="70" header-align="center">
          <template #default="{ row }">
            {{getUserState(row)}}
          </template>
        </vxe-column>
        <vxe-column field="code" title="登录名" width="200"></vxe-column>
        <vxe-column field="name" title="姓名" width="200"></vxe-column>
        <vxe-column field="mobile" title="手机号" width="150"></vxe-column>
        <vxe-column field="createTime" title="注册时间" width="180"></vxe-column>
        <vxe-column field="custCount" title="参管项目数" width="90" header-align="center"></vxe-column>
        <vxe-column field="weiXinCount" title="注册微信" width="80" header-align="center">
          <template #default="{ row }">
            {{row.weiXinCount > 0 ? '已注册' : '未注册'}}
          </template>
        </vxe-column>
        <vxe-column>
          <template #header>
            <Button v-if="funCodes('sua')" size="small" type="primary" style="margin-right: 5px" @click="handleAdd">新增</Button>
          </template>
          <template #default="{ row }">
            <Button v-if="row.locked && funCodes('sue1')" size="small" type="success" style="margin-right: 5px" @click="handleLocked(row)">解锁</Button>
            <Button v-if="row.id != user.id && funCodes('sue1')" size="small" :type="row.active ? 'warning' : 'success'" style="margin-right: 5px" @click="handleEnable(row)">{{row.active ? '禁用' : '启用'}}</Button>
            <Button v-if="funCodes('sue')" size="small" type="primary" style="margin-right: 5px" @click="handleCust(row)">参管项目</Button>
            <Button v-if="funCodes('sue')" size="small" type="primary" style="margin-right: 5px" @click="handleEdit(row)">编辑</Button>
            <!-- <Button v-if="funCodes('sue')" size="small" type="primary" style="margin-right: 5px" @click="setPermissions(row)">设置权限</Button> -->
            <Button v-if="row.id != user.id && funCodes('sud')" size="small" type="error" style="margin-right: 5px" @click="handleDelete(row)">删除</Button>
          </template>
        </vxe-column>
      </vxe-table>
    </div>
    <vxe-pager :layouts="pageLayouts" :current-page.sync="filter.index" :page-size.sync="filter.size" :total="total" :page-sizes='pageSizes' @page-change="handlePageChange"></vxe-pager>
    <ModalUserEdit v-model="showEditModal" :item="editItem" @saved="itemSaved" />
    <ModalUserCust v-model="showCustModal" :item="editItem" @saved="getList" />
    <!-- <ModalPowerEdit v-model="showPowerModal" :item="editItem" @saved="itemSaved" /> -->
    <!-- <ModalUserList v-model="showUserModal" :item="editItem" /> -->
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import ModalUserEdit from './ModalUserEdit'
import ModalUserCust from './ModalUserCust'
// import ModalPowerEdit from '../cust/user/ModalRoleAndGroup.vue'
export default {
  name: 'SiteManageCust',
  components: {
    ModalUserEdit,
    ModalUserCust,
    // ModalPowerEdit,
  },
  props: {
  },
  data() {
    return {
      list: [],
      total: 0,
      tabHeight: 600,
      editItem: { isAdd: true, data: {} },
      showEditModal: false,
      showCustModal: false,
      showUserModal: false,
      // showPowerModal: false,
      filter: {
        code: '',
        name: '',
        mobile: '',
        index: 1,
        size: 20,
      },
      treeList: [],
      showTreeList: [],
      keyword: "",
      tabRefresh: 0,
      profiles: [],
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes']),
    ...mapState('cmd', ['windowOnResize']),
    ...mapState('auth', ['token', 'appType', 'isNewMode', 'user']),
    ...mapState('common', ['pageLayouts', 'pageSizes']),
  },
  watch: {
    windowOnResize() {
      this.setTableHeight();
    },
  },
  mounted: function () {
    setTimeout(this.setTableHeight, 100);
    this.search();
  },
  destroyed: function () {
  },
  methods: {
    handlePageChange({ currentPage, pageSize }) {
      this.filter.index = currentPage
      this.filter.size = pageSize
      this.getList()
    },
    getUserState: function (params) {
      if (!params.active) {
        return '已停用';
      }
      if(params.locked){
        return '已锁定';
      }
      if (params.needExpire) {
        let expire = new Date(params.expire);
        expire.setDate(expire.getDate() + 1);
        if (new Date().getTime() > expire.getTime()) {
          return '已过期';
        }
      }
      return '正常';
    },
    search: function(){
      this.filter.index = 1;
      this.getList();
    },
    getProfileName: function(row){
      if(!row.enable)return '-';
      if(!row.profile)return '默认风格';
      let ps = this.profiles.filter(p => p.code == row.profile);
      if(ps.length == 0)return '默认风格';
      return ps[0].name;
    },
    setTableHeight: function () {
      this.tabHeight = this.$refs.table1.clientHeight - 0;
    },
    handleCust: function (params) {
      this.editItem = params;
      this.showCustModal = true;
    },
    handleUser: function (params) {
      if (!params.enable) {
        this.$Message.warning('请先启用应用');
        return;
      }
      this.editItem = params;
      this.showUserModal = true;
    },
    handleAdd: function () {
      this.editItem = { isAdd: true, data: {} };
      this.showEditModal = true;
    },
  
    handleEdit: function (params) {
      this.editItem = { isAdd: false, data: params };
      this.showEditModal = true;
    },
    itemSaved: function () {
      this.getList();
    },
    handleLocked: function (row) {
      this.$Modal.confirm({
        title: "提示",
        content: `确定要解锁用户[${row.name}]吗?`,
        onOk: async () => {
          this.$axios.post(`site/customer/UnlockUserOld`, { id: row.id }).then(res => {
            if (res.code == 0) {
              this.getList();
            }
          });
        }
      });
    },
    handleEnable: function (params) {
      this.$Modal.confirm({
        title: "提示",
        content: `确定要${params.active ? '禁用' : '启用'}用户[${params.name}]吗?`,
        onOk: async () => {
          this.$axios.post(`site/customer/EnableUserOld`, { id: params.id, active: !params.active }).then(res => {
            if (res.code != 0) {
              // this.$Message.error(res.data);
            } else {
              // this.getList();
              params.active = !params.active;
              this.$Message.info(!params.active ? '禁用成功' : '启用成功');
            }
          });
        }
      });
    },
    handleDelete: function (params) {
      this.$Modal.confirm({
        title: "提示",
        content: `确定要删除用户[${params.code}]吗?`,
        onOk: async () => {
          this.$axios.post(`site/customer/DeleteUserOld`, { id: params.id }).then(res => {
            if (res.code == 0) {
              this.getList();
            }
          });
        }
      });
    },
    getCustItem: function (id) {
      let cs = this.treeList.filter(p => p.id == id);
      if (cs.length == 0) return null;
      return cs[0];
    },
    getList: function () {
      this.$axios.post(`site/customer/QueryUserByFilterOld`, this.filter).then(res => {
        if (res.code == 0) {
          this.$set(this, 'list', res.data.list);
          this.$set(this, 'total', res.data.count);
        }
      });
    },
    seqMethod({ row }) {
      return `${row.level}级：${row.name}`
    },
    keywordBut() {
      this.initTreeData();
      if (this.keyword == "") {
        this.$set(this, 'showTreeList', this.treeList);
      }
      let arr = [];
      this.treeList.forEach(el => {
        if (el.name.indexOf(this.keyword) > -1) {
          arr.push(el);
        }
      });
      this.$set(this, 'showTreeList', arr);
      this.tabRefresh++
    },
    initTreeData: function () {
      this.treeList.forEach(el => {
        el.level = 1;
        let cs = this.treeList.filter(p => p.id == el.parentId);
        if (cs.length > 0) {
          el.level = cs[0].level + 1;
        }
      });
      this.treeList.forEach(el => {
        if (isNaN(el.level)) {
          this.initTreeData();
        }
      });
    },
  }
}
</script>
<style scoped>
.alarm-rule-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  /* padding: 10px; */
  /* padding-right: 40px; */
  /* border: solid 1px red; */
}
.query-data-area {
  height: 45px;
  flex: none;
  display: flex;
  align-items: center;
  /* border: solid 1px rgba(143, 138, 138, 0.452); */
  margin-bottom: 5px;
}
.alarm-data-area {
  /* border: solid 1px red; */
  height: 100px;
  flex: auto;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
  /* padding-left: 10px; */
}
.station-alarm-tabs {
  height: 30px;
  flex: none;
  display: flex;
}
.station-alarm-tab {
  padding: 3px 8px;
  margin: 3px 3px 0 3px;
  border: solid 1px lightgray;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  background-color: white;
}
.tab-active {
  border: solid 1px rgb(135, 159, 224);
  background-color: rgb(135, 159, 224);
  color: white;
}
.station-alarm-tabcontent {
  height: 500px;
  flex: auto;
  /* border: solid 1px red; */
}
/* .box-body{
  height: 0;
  flex: auto;
  margin-top: 5px;
  border: solid 1px red;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}
.box-body::-webkit-scrollbar {
  display: none;
} */
</style>