<template>
  <div class="search-data-container">
    <div class="search-data-header">
      <div class="search-data-query">
        网关：
        <Select v-model="form.gateId" style="width: 200px; margin: 0 10px">
          <Option :value="0">全部网关</Option>
          <Option :value="l.id" v-for="l in gates" :key="l.id">{{l.name}}</Option>
        </Select>
        起始时间：
        <DatePicker v-model="form.start" type="datetime" placeholder="请选择开始时间" style="width: 170px; margin-right: 10px;"></DatePicker>
        截止时间：
        <DatePicker v-model="form.end" type="datetime" placeholder="请选择截止时间" style="width: 170px; margin-right: 10px;"></DatePicker>
        <Button v-if="funCodes('stsv')" type="info" :loading="loading" @click="getList">查询</Button>
        <Button type="success" @click="exportData" style="margin:3px 10px;">导出</Button>
      </div>
    </div>
    <div class="search-data-content" ref="table1">
      <vxe-table border resizable show-overflow show-header-overflow keep-source ref="chkTable" :export-config="{}" :key="dataRefresh" :data="history" height="600" :row-config="{isHover: true}" :checkbox-config="{checkField: 'checked',}">
        <vxe-column field="deviceName" title="网关名称" width="200">
          <template #default="{ row }">
            {{row.deviceName}}
          </template>
        </vxe-column>
        <vxe-column field="createTime" title="记录时间">
          <template #default="{ row }">
            {{row.createTime}}
          </template>
        </vxe-column>
        <vxe-column field="en" title="是否在线">
          <template #default="{ row }">
            {{row.online ? '在线' : '离线'}}
          </template>
        </vxe-column>
      </vxe-table>
      <Spin size="large" fix v-if="loading"></Spin>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
// import exportXlsx from '@/components/xlsx/exportXlsx'
// import TempHistoryData from './PowerHistoryItem'
// import VirtualList from 'vue-virtual-scroll-list'
export default {
  name: 'SearchPowerboxIndex',
  components: {
    // VirtualList,
    // 'virtual-list': VirtualList
  },
  props: {
  },
  data() {
    return {
      loading: false,
      tabHeight: 1,
      form: {
        gateId: 0,
        start: "",
        end: "",
      },
      history: [],
      gates: [],
      dataRefresh: 0,
      tabData: [],
      mergeData: [],
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes']),
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['weeks', 'productCodes', 'alarmTypes', 'alarmLevels']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    selectedStations: function () {
      return this.selectedDevices.filter(p => p.type == this.productCodes.station);
    },
    selectedStationIds: function () {
      let pids = this.selectedDevices.filter(p => p.type == this.productCodes.station).map(p => { return p.id });
      return pids;
    },
  },
  watch: {
    windowOnResize() {
      this.setTableHeight();
    },
  },
  mounted: function () {
    let now = new Date();
    now.setDate(now.getDate() + 1);
    this.form.end = now.format('yyyy-MM-dd HH:mm:ss');
    now.setDate(now.getDate() - 3);
    this.form.start = now.format('yyyy-MM-dd HH:mm:ss');
    setTimeout(() => {
      this.setTableHeight();
    }, 100);
    this.initGates();
  },
  destroyed: function () {
  },
  methods: {
    initGates: function () {
      if (this.form.stationId <= 0) {
        this.$set(this, 'lights', []);
        this.form.gateId = 0;
        return;
      }
      this.$axios.post(`//${this.domains.trans}/station/light/QueryGateWay`, { }).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          this.$set(this, 'gates', res.data);
          // console.log('get light data', res.data)
          // if (res.data.length > 0) {
          //   this.form.gateId = res.data[0].id;
          // } else {
          //   this.form.gateId = 0;
          // }
        }
      });
    },
    setTableHeight: function () {
      this.tabHeight = this.$refs.table1.clientHeight - 5;
    },
    setDefaultStation: function () {
      if (this.selectedStations.length == 0) {
        this.form.stationId = 0;
        return;
      }
      if (this.form.stationId == 0) {
        this.form.stationId = this.selectedStations[0].id;
        return;
      }
      let ss = this.selectedStations.filter(p => p.id == this.form.stationId)
      if (ss.length == 0) {
        this.form.stationId = this.selectedStations[0].id;
      }
    },
    exportData() {
      this.$refs.chkTable.openExport()
    },
    getList() {
      // if (this.form.gateId == 0) {
      //   this.$Message.warning('请选择要查询的网关');
      //   return;
      // }
      this.form.start = new Date(this.form.start).format("yyyy-MM-dd HH:mm:ss");
      this.form.end = new Date(this.form.end).format("yyyy-MM-dd HH:mm:ss");
      let params = { gateId: this.form.gateId, start: this.form.start, end: this.form.end };
      this.$axios.post(`//${this.domains.trans}/station/light/QueryGateTrans`, params).then(res => {
        this.loading = false;
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          this.$set(this, 'history', res.data);
        }
      });
    },
  }
}
</script>
<style scoped>
.active {
  background-color: #f2f6fa;
}
td {
  height: 40px;
  white-space: nowrap;
  width: 120px;
  border: solid 1px #cfe3f7;
}
.sub-header {
  background-color: #cfe3f7;
  /* background: #CFE3F7; */
  height: 46px;
  text-align: center;
}
.history-item {
  /* border-width: 1px;
  border-collapse: collapse;
  border-color: cyan; */
  width: 100%;
  background-color: white;
  margin-bottom: 30px;
}
.search-data-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  /* border: solid 1px blue; */
}
.search-data-header {
  height: 40px;
  flex: none;
  display: flex;
  /* border: solid 1px blue; */
}
.search-data-content {
  height: 100px;
  flex: auto;
  overflow-y: auto;
  /* border: solid 1px blue; */
}
.search-data-query {
  width: 830px;
  flex: auto;
  background-color: white;
  /* margin: 3px 1px; */
  display: flex;
  align-items: center;
  padding: 10px;
  /* border: solid 1px blue; */
  white-space: nowrap;
}
.search-data-view {
  width: 300px;
  flex: auto;
  display: flex;
  align-items: center;
  /* border: solid 1px blue; */
  white-space: nowrap;
  padding: 10px;
}
.table-data {
  overflow: auto;
}
</style>