<template>
  <div class="search-data-container">
    <div class="search-data-header">
      <div class="search-data-query">
        单灯：
        <Select v-model="form.lightId" style="width: 100px; margin: 0 10px">
          <Option :value="l.id" v-for="l in lights" :key="l.id">{{l.name}}</Option>
        </Select>
        起始时间：
        <DatePicker v-model="form.start" type="datetime" placeholder="请选择开始时间" style="width: 170px; margin-right: 10px;"></DatePicker>
        截止时间：
        <DatePicker v-model="form.end" type="datetime" placeholder="请选择截止时间" style="width: 170px; margin-right: 10px;"></DatePicker>
        <Button v-if="funCodes('slhv')" type="info" :loading="loading" @click="getList">查询</Button>
        <Button type="success" @click="exportData" style="margin:3px 10px;">导出</Button>
      </div>
    </div>
    <div class="search-data-content" ref="table1">
      <vxe-table border resizable show-overflow show-header-overflow keep-source ref="chkTable" :export-config="{}" :key="dataRefresh" @checkbox-change="selectChangeEvent" :data="tabData" height="600" :row-config="{isHover: true}" :checkbox-config="{checkField: 'checked',}" :merge-cells="mergeData">
        <vxe-column field="fireTime" title="时间" width="200">
          <template #default="{ row }">
            {{row.fireTime}}
          </template>
        </vxe-column>
        <vxe-column field="name" title="灯杆名称">
          <template #default="{ row }">
            {{row.name}}
          </template>
        </vxe-column>
        <vxe-column field="en" title="灯头名称">
          <template #default="{ row }">
            {{row.en}}
          </template>
        </vxe-column>
        <vxe-column field="u" title="电压(V)">
          <template #default="{ row }">
            {{row.u}}
          </template>
        </vxe-column>
        <vxe-column field="c" title="电流(A)">
          <template #default="{ row }">
            {{row.c}}
          </template>
        </vxe-column>
        <vxe-column field="p" title="功率(W)">
          <template #default="{ row }">
            {{row.p}}
          </template>
        </vxe-column>
        <vxe-column field="op" title="亮度(%)">
          <template #default="{ row }">
            {{row.op}}
          </template>
        </vxe-column>

      </vxe-table>
      <Spin size="large" fix v-if="loading"></Spin>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
// import exportXlsx from '@/components/xlsx/exportXlsx'
// import TempHistoryData from './PowerHistoryItem'
// import VirtualList from 'vue-virtual-scroll-list'
export default {
  name: 'SearchPowerboxIndex',
  components: {
    // VirtualList,
    // 'virtual-list': VirtualList
  },
  props: {
  },
  data() {
    return {
      loading: false,
      curTab: 'group',
      tabHeight: 1,
      // tempHistoryData: TempHistoryData,
      pos: {},
      tabHead: { //头部标题
        name: "名字",
        sex: "性别",
        age: "年龄",
      },
      form: {
        stationId: 0,
        lightId: 0,
        start: "",
        end: "",
      },
      viewCode: '',
      showTable: true,
      history: [],
      searchLight: null,
      lights: [],
      dataRefresh: 0,
      tabData: [],
      mergeData: [],
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes']),
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['weeks', 'productCodes', 'alarmTypes', 'alarmLevels']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    selectedStations: function () {
      return this.selectedDevices.filter(p => p.type == this.productCodes.station);
    },
    selectedStationIds: function () {
      let pids = this.selectedDevices.filter(p => p.type == this.productCodes.station).map(p => { return p.id });
      return pids;
    },
  },
  watch: {
    selectedNode() {
      this.initLights();
    },
    windowOnResize() {
      this.setTableHeight();
    },
    'form.stationId'() {
      this.initLights();
    },
  },
  mounted: function () {
    let now = new Date();
    now.setDate(now.getDate() + 1);
    this.form.end = now.format('yyyy-MM-dd HH:mm:ss');
    now.setDate(now.getDate() - 3);
    this.form.start = now.format('yyyy-MM-dd HH:mm:ss');
    setTimeout(() => {
      this.setTableHeight();
    }, 100);
    this.initLights();
    // this.getList();
  },
  destroyed: function () {
  },
  methods: {
    initLights: function () {
      this.$axios.post(`//${this.domains.trans}/station/light/QueryLight`, { groupId: this.selectedNode.id }).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          this.$set(this, 'lights', res.data);
          // console.log('get light data', res.data)
          if (res.data.length > 0) {
            this.form.lightId = res.data[0].id;
          } else {
            this.form.lightId = 0;
          }
        }
      });
    },
    setTableHeight: function () {
      this.tabHeight = this.$refs.table1.clientHeight - 5;
    },
    exportData() {
      this.$refs.chkTable.openExport()
    },
    getList() {
      if (this.form.lightId == 0) {
        this.$Message.warning('请选择要查询的单灯');
        return;
      }
      this.form.start = new Date(this.form.start).format("yyyy-MM-dd HH:mm:ss");
      this.form.end = new Date(this.form.end).format("yyyy-MM-dd HH:mm:ss");
      let params = { paramId: this.form.lightId, start: this.form.start, end: this.form.end };
      let ls = this.lights.filter(p => p.id == this.form.lightId);
      if (ls.length > 0) {
        this.searchLight = ls[0];
      } else {
        this.searchLight = null;
      }
      this.$axios.post(`//${this.domains.trans}/station/param/history`, params).then(res => {
        this.loading = false;
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          res.data.forEach(el => {
            for (let attr in el.content) {
              if (!isNaN(el.content[attr]) && el.content[attr] < 0) {
                el.content[attr] = 0
              }
            }
          });
          this.$set(this, 'history', res.data);
          this.makeData(res.data)
        }
      });
    },
    selectChangeEvent({ rowIndex }) {
      this.$refs.chkTable.reloadRow(this.dataList, null, rowIndex)
    },
    spanData() {
      this.mergeData = [];
      let spanCols = [{
        name: '时间',
        col: 0
      }, {
        name: '灯杆名称',
        col: 1
      }, {
        name: '电压(V)',
        col: 3
      }];
      if (this.tabData.length < 2) {
        return false;
      }
      let data = "";
      let index = { row: 1, col: 1, rowspan: 1, colspan: 1 }
      for (let i = 0; i < this.tabData.length; i++) {
        if (this.tabData[i + 1] && this.tabData[i].lid == this.tabData[i + 1].lid) {
          if (index.rowspan == 1) {
            index.row = i;
          }
          index.rowspan++;
        } else {
          if (index.rowspan > 1) {
            for (let p = 0; p < spanCols.length; p++) {
              index.col = spanCols[p].col;
              data = JSON.parse(JSON.stringify(index));
              this.mergeData.push(data)
            }
          }
          index.rowspan = 1;
        }
      }
      this.dataRefresh++;
    },
    makeData(data) {
      let arr = [];
      data.forEach((el, index) => {
        let oneOb = {
          lid: index,
          name: this.searchLight.name,
          en: this.searchLight.content.en1 ? this.searchLight.content.nm1 : '--',
          fireTime: new Date(el.fireTime).format('yyyy-MM-dd HH:mm:ss'),
          u: el.content.u,
          c: el.content.c1,
          p: el.content.p1,
          op: el.content.op1,
        }
        let towOb = {
          lid: index,
          name: this.searchLight.name,
          en: this.searchLight.content.en2 ? this.searchLight.content.nm2 : '--',
          fireTime: new Date(el.fireTime).format('yyyy-MM-dd HH:mm:ss'),
          u: el.content.u,
          c: el.content.c2,
          p: el.content.p2,
          op: el.content.op2,
        }
        arr.push(oneOb)
        arr.push(towOb)
      });
      this.$set(this, 'tabData', arr);
      this.spanData();
    },
  }
}
</script>
<style scoped>
.active {
  background-color: #f2f6fa;
}
td {
  height: 40px;
  white-space: nowrap;
  width: 120px;
  border: solid 1px #cfe3f7;
}
.sub-header {
  background-color: #cfe3f7;
  /* background: #CFE3F7; */
  height: 46px;
  text-align: center;
}
.history-item {
  /* border-width: 1px;
  border-collapse: collapse;
  border-color: cyan; */
  width: 100%;
  background-color: white;
  margin-bottom: 30px;
}
.search-data-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  /* border: solid 1px blue; */
}
.search-data-header {
  height: 40px;
  flex: none;
  display: flex;
  /* border: solid 1px blue; */
}
.search-data-content {
  height: 100px;
  flex: auto;
  overflow-y: auto;
  /* border: solid 1px blue; */
}
.search-data-query {
  width: 830px;
  flex: auto;
  background-color: white;
  /* margin: 3px 1px; */
  display: flex;
  align-items: center;
  padding: 10px;
  /* border: solid 1px blue; */
  white-space: nowrap;
}
.search-data-view {
  width: 300px;
  flex: auto;
  display: flex;
  align-items: center;
  /* border: solid 1px blue; */
  white-space: nowrap;
  padding: 10px;
}
.table-data {
  overflow: auto;
}
</style>